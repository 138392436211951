import {Nav, Navbar, Image, Button} from "react-bootstrap";
import logo from "../assets/images/logo.png";

export default function Header() {
    const onClickSignIn = (page)=>{
        window.location.href = `https://app.${window.location.host.replace("www.", "")}`;
    };
    const onClickRegister = (page)=>{
        window.location.href = `https://app.${window.location.host.replace("www.", "")}/sign-up`;
    };

    return (
        <Navbar
            bg="light"
            expand="md"
            style={{paddingLeft: "10%"}}
        >
            <Navbar.Brand href="/">
                <Image
                    src={logo}
                    alt="Efficient"
                    fluid
                    style={{maxHeight: "50px", objectFit: "contain"}}
                />
            </Navbar.Brand>

            <Navbar.Toggle
                aria-controls="basic-navbar-nav"
                style={{marginRight: "20px"}}
            />

            <Navbar.Collapse id="basic-navbar-nav" >
                <Nav className="me-auto">
                    <Nav.Link href="about">About</Nav.Link>
                    <Nav.Link href="process">Process</Nav.Link>
                    <Nav.Link href="services">Services</Nav.Link>
                    <Nav.Link href="certification">Certification</Nav.Link>
                    <Nav.Link href="contact">Contact</Nav.Link>
                </Nav>
                <Button
                    variant="dark"
                    onClick={()=>onClickRegister()}
                    style={{width: "100px"}}>
                        REGISTER
                </Button>
                <Button
                    onClick={()=>onClickSignIn()}
                    style={{
                        marginLeft: "20px",
                        marginRight: "20px",
                        width: "100px",
                    }}>
                    SIGN IN
                </Button>
            </Navbar.Collapse>
        </Navbar>
    );
}
